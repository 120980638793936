import crudGetters from "@/store/templates/crud/getters";

export default {
  invoice: state => state.model,
  id: state => state.model.id,
  company: state => state.company[state.model.company],
  modelCompany: state => state.model.company,
  vatRate: state => state.model.vatRate,
  amount: state => state.model.amount,
  vatAmount: state => state.model.vatAmount,
  totalAmount: state => state.model.totalAmount,
  balance: state => state.model.balance,
  due: state => state.model.amount - state.model.balance,
  state: state => state.model.state,
  number: state => state.model.number,
  dueDate: state => state.model.dueAt,
  month: state => state.model.createdAt,
  street: state =>
    state.address[state.company[state.model.company].fiscalAddress].street,
  streetNumber: state =>
    state.address[state.company[state.model.company].fiscalAddress]
      .streetNumber,
  cityName: state =>
    state.address[state.company[state.model.company].fiscalAddress].cityName,
  postalCode: state =>
    state.address[state.company[state.model.company].fiscalAddress].postalCode,
  country: state =>
    state.country[
      state.address[state.company[state.model.company].fiscalAddress].country
    ].name,
  items: state => state.model.items,
  offers: state => state.model.offers,
  itemAmount: state => index => state.model.items[index].amount,
  tempItem: state => state.tempItem,
  subtotal: state =>
    state.model.items.reduce((t, { totalPrice }) => t + totalPrice, 0),
  companyVatRate: state => state.companyVatRate,
  companyRejectableTerm: state => state.companyRejectableTerm,
  hasRejectableOffers: state => state.model.hasRejectableOffers,
  hasRejectionRequestedOffers: state => state.model.hasRejectionRequestedOffers,
  showWarnings: (state, getters) =>
    state.model.state === "DRAFT" &&
    (getters.hasRejectionRequestedOffers || getters.hasRejectableOffers),
  draftedByFullName: state =>
    state.employee[state.user[state.model.createdBy]?.employee]?.fullName,
  createdAt: state => state.model.createdAt,
  openedByFullName: state =>
    state.employee[state.user[state.model.openedBy]?.employee]?.fullName,
  openedAt: state => state.model.invoicedAt,
  paidByFullName: state =>
    state.employee[state.user[state.model.paidBy]?.employee]?.fullName,
  paidAt: state => state.model.paidAt,
  type: state => state.model.type,
  creditNotes: state => state.model.creditNotes,
  pdfDownloadable: state => state.model.pdfDownloadable,
  offerAcceptanceRate: state => state.model.offerAcceptanceRate,
  distributedUntill: state => state.distributedUntill,
  invoiceableOffers: state => state.invoiceableOffers,
  offer: state => offerId => state.offer[offerId],
  lead: (state, getters) => offerId => state.lead[getters.offer(offerId).lead],
  submission: (state, getters) => offerId =>
    state.submission[getters.lead(offerId).submission],
  address: (state, getters) => offerId =>
    state.address[getters.submission(offerId).address],
  isLoadingOffers: state => state.isLoadingOffers,
  offersById: state => offerIds =>
    offerIds.map(offerId => state.offer[offerId]),
  isUpdatingItem: state => state.isUpdatingItem,
  ...crudGetters
};
