import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import initialState from "./state";
import itemOffersTemplateModule from "./item-offers-template";

export default {
  namespaced: true,
  state: () => initialState(),
  getters,
  actions,
  mutations,
  modules: {
    itemOffersTemplate: itemOffersTemplateModule
  }
};
